import React from 'react';
import { BrowserRouter as Router, NavLink, Route, Switch } from 'react-router-dom';
import { Button, Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import CreateUser from './components/create-tenant';
import UpdateTenantPage from './components/update-tenant';
import LogoIcon from './assets/images/plum_asset_logo.svg';
import ListStreamSubscriptions from './components/list-stream-subscriptions';
import CreateStreamSubscription from './components/create-stream-subscription';
import theme from './components/shared/theme';
import { ThemeProvider } from 'styled-components';
import { Slide, ToastContainer } from 'react-toastify';

export const Routes: React.FC<{ onSignOut(): void }> = ({ onSignOut }) => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <ToastContainer transition={Slide} />
        <Navbar>
          <Navbar.Brand>
            <LogoIcon />
          </Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <NavLink to="/tenant/create" className={'px-2'}>
                Create Tenant
              </NavLink>
              <NavLink to="/tenant/update" className={'px-2'}>
                Update Tenant
              </NavLink>
              <NavLink to="/stream/subscriptions" className={'px-2'}>
                Stream Subscriptions
              </NavLink>
              <NavLink to="/stream/subscribe" className={'px-2'}>
                Create Stream Subscription
              </NavLink>
            </Nav>
            <Nav>
              <Nav.Link eventKey={1} as="div">
                <Button onClick={onSignOut}>Sign Out</Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Container>
          <Row>
            <Col md={12}>
              <Switch>
                <Route exact path="/" component={CreateUser as any} />
                <Route path="/tenant/create" component={CreateUser as any} />
                <Route path="/tenant/update" component={UpdateTenantPage as any} />
                <Route path="/stream/subscriptions" component={ListStreamSubscriptions as any} />
                <Route path="/stream/subscribe" component={CreateStreamSubscription as any} />
              </Switch>
            </Col>
          </Row>
        </Container>
      </ThemeProvider>
    </Router>
  );
};
