import { instance, addIdTokenToHeaders } from './axios';
import { Auth } from '@aws-amplify/auth';

/**
 * Decorator wraps around a API method.
 * Will try and refresh the Cognito auth token and retry request if first attempt
 * resulted in a 401
 */
const hydrateApiAuth = async () => {
  if (!instance.defaults.headers.common.Authorization) {
    addIdTokenToHeaders();
  }

  const session = await Auth.currentSession();
  instance.defaults.headers.common.Authorization = session.getIdToken().getJwtToken();
};

export const refreshAuthToken = () =>
  function (_target: object, _propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args: any[]) {
      try {
        const response = await originalMethod.apply(this, args);
        return response;
      } catch (error) {
        if (!error?.response?.status || error?.response?.status === 401) {
          try {
            await hydrateApiAuth();
            return originalMethod.apply(this, args);
          } catch (error) {
            throw new Error(
              error?.response?.data?.error?.message || 'Error making api request. Refresh the page and try again'
            );
          }
        }

        throw new Error(
          error?.response?.data?.error?.message || 'Error making api request. Refresh the page and try again'
        );
      }
    };

    return descriptor;
  };
