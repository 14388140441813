import React, { useEffect, useState } from 'react';
import { sportsStreamApi, SportsStreamSubscription, GetStreamSubscriptionsParams } from '../../api/sports-stream-api';
import { Button, Form, FormControl, FormGroup, FormLabel, Spinner, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ListStreamSubscriptions = () => {
  const [loading, setLoading] = useState(false);
  const [streamSubscriptions, setStreamSubscriptions] = useState<SportsStreamSubscription[]>([]);
  const [tenantId, setTenantId] = useState<string>();
  const [teamId, setTeamId] = useState<string>();

  const getSubscriptions = async (getStreamParams: GetStreamSubscriptionsParams) => {
    try {
      await sportsStreamApi
        .getSubscriptions(getStreamParams)
        .then((result) => setStreamSubscriptions(result.subscriptions));
    } catch (e) {
      toast(`Fetching stream subscriptions failed: ${e.message}`, {
        position: 'bottom-right',
        autoClose: 5000,
        type: 'error',
        hideProgressBar: true,
        closeOnClick: false,
        closeButton: false,
        pauseOnHover: false,
        draggable: false
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    await getSubscriptions({ ...(tenantId ? { tenantId } : {}), ...(teamId ? { teamId } : {}) });
    setLoading(false);
  };

  const onChangeTenantId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTenantId(e.currentTarget.value);
  };

  const onChangeTeamId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamId(e.currentTarget.value);
  };

  useEffect(() => {
    setLoading(true);
    getSubscriptions({});
    setLoading(false);
  }, []);

  return (
    <div>
      <h2>Stream Subscriptions</h2>
      <h4>Enter the tenant's ID whose subscription you would like to view:</h4>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <FormLabel>Tenant Id</FormLabel>
          <FormControl type="tenantId" onChange={onChangeTenantId} value={tenantId} />
        </FormGroup>
        <FormGroup>
          <FormLabel>Team Id</FormLabel>
          <FormControl type="teamId" onChange={onChangeTeamId} value={teamId} />
        </FormGroup>
        <Button type="submit" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : streamSubscriptions.length > 0 ? 'Update' : 'View'}
        </Button>
      </Form>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Subscription ID</th>
            <th>Tenant ID</th>
            <th>Team ID</th>
            <th>Events</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {streamSubscriptions.map(({ id, teamId, events, createdAt, updatedAt, tenantId }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{tenantId}</td>
              <td>{teamId}</td>
              <td>
                {events.map((event, index) => (
                  <p key={index}>{event.type}</p>
                ))}
              </td>
              <td>{createdAt}</td>
              <td>{updatedAt}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ListStreamSubscriptions;
