import React from 'react';
import { createRoot } from 'react-dom/client';
import './config/amplify';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import './assets/fonts/fonts.css';
import { App } from './App';

const root = createRoot(document.getElementById('root'));
root.render(<App />);
