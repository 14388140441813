import React from 'react';
import { InputSection, StyledSpan } from './styles';

export interface Props {
  name: string;
  value: string;
  label?: string;
  subLabel?: string;
  className?: string;
  placeholder?: string;
  invalid?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  inputEvents?: React.DOMAttributes<HTMLInputElement>;

  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const TextField = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <InputSection
      className={props.className}
      invalid={props.invalid}
      data-testid={props.name}
      $disabled={props.disabled}
    >
      {props.label && (
        <label htmlFor={props.name}>
          {props.label} <span>{props.subLabel}</span>
        </label>
      )}
      <input
        {...props.inputEvents}
        ref={ref}
        name={props.name}
        readOnly={!props.onChange}
        disabled={props.disabled}
        type="text"
        value={props.value || ''}
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
      {props.icon && <StyledSpan>{props.icon}</StyledSpan>}
    </InputSection>
  );
});
