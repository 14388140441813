import { Amplify } from '@aws-amplify/core';

const region = 'eu-west-1';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region,
    userPoolWebClientId: process.env.APP_CLIENT_ID,
    identityPoolId: process.env.IDENTITY_POOL_ID,
    userPoolId: process.env.USER_POOL_ID
  }
});
