import { AxiosInstance } from 'axios';
import { instance } from './tools/axios';
import { refreshAuthToken } from './tools/decorator';

export interface Tenant {
  id: string;
  email: string;
  name: string;
  eventsExpireIn: number;
  licenses: string[];
  parentId?: string;
  cloudfrontDistributionId?: string;
  createdAt?: string;
}

interface CreateParams extends Pick<Tenant, 'email' | 'name' | 'eventsExpireIn'> {}

export class TenantApiClass {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async get(): Promise<{ tenants: Tenant[] }> {
    const response = await this.axios.get('/tenants');
    return response.data;
  }

  @refreshAuthToken()
  async create(data: CreateParams): Promise<{ tenant: Tenant }> {
    const response = await this.axios.post('/tenant', data);
    return response.data;
  }

  @refreshAuthToken()
  async update(data: Partial<Tenant>): Promise<{ tenant: Tenant }> {
    const response = await this.axios.patch('/tenant', data);
    return response.data;
  }

  @refreshAuthToken()
  async link(parentId: string, childId: string) {
    const response = await this.axios.post('/tenants/link', { tenants: { parentId, childId } });
    return response.data;
  }

  @refreshAuthToken()
  async unlink(parentId: string, childId: string) {
    const response = await this.axios.delete('/tenants/link', { params: { parentId, childId } });
    return response.data;
  }

  @refreshAuthToken()
  async createCloudfrontDistribution(tenantId: string) {
    const response = await this.axios.get('/tenant/distribution', { params: { tenantId } });
    return response.data;
  }

  @refreshAuthToken()
  async getCloudfrontDistributionStatus(tenantId: string) {
    const response = await this.axios.get('/tenant/distribution/status', { params: { tenantId } });
    return response.data;
  }

  @refreshAuthToken()
  async disableCloudfrontDistribution(tenantId: string) {
    const response = await this.axios.get('/tenant/distribution/disable', { params: { tenantId } });
    return response.data;
  }

  @refreshAuthToken()
  async enableCloudfrontDistribution(tenantId: string) {
    const response = await this.axios.get('/tenant/distribution/enable', { params: { tenantId } });
    return response.data;
  }

  @refreshAuthToken()
  async deleteCloudfrontDistribution(tenantId: string) {
    const response = await this.axios.delete('/tenant/distribution', { params: { tenantId } });
    return response.data;
  }
}

export const tenantApi = new TenantApiClass(instance);
