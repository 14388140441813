import { AxiosInstance } from 'axios';
import { instance } from './tools/axios';
import { refreshAuthToken } from './tools/decorator';

export interface GetStreamSubscriptionsParams {
  tenantId?: string;
  teamId?: string;
}

export interface GetStreamTeamsResponse {
  league: {
    id: string;
    name: string;
    alias: string;
  };
  teams: {
    id: string;
    name: string;
    alias: string;
    market: string;
  }[];
}

export interface GetStreamTeamParams {
  sportRadarApiKey: string;
}

export interface SportsStreamSubscriptionEvent {
  type: string;
  templateJson: string;
  segmentId: string;
  videoTemplateUrl?: string;
  targetUrl?: string;
}

export interface SportsStreamSubscription {
  teamId: string;
  seasonId?: string;
  tenantId: string;
  events: SportsStreamSubscriptionEvent[];
  id: string;
  createdAt: string;
  updatedAt: string;
}

interface GetSportsStreamSubscriptionsResponse {
  subscriptions: SportsStreamSubscription[];
}

export interface CreateSportsStreamSubscriptionParams extends Omit<SportsStreamSubscription, '_id'> {
  sport: 'wnba' | 'nba' | 'nhl';
  sportRadarApiKey: string;
  apiKey: string;
  apiKeySecret: string;
}

export class SportsStreamApi {
  constructor(private axios: AxiosInstance) {}

  @refreshAuthToken()
  async getSubscriptions(params: GetStreamSubscriptionsParams) {
    const response = await this.axios.get<GetSportsStreamSubscriptionsResponse>(
      '/sports-stream/sportradar/subscriptions',
      {
        params
      }
    );

    return response.data;
  }

  @refreshAuthToken()
  async createSubscription(params: CreateSportsStreamSubscriptionParams) {
    const response = await this.axios.post<SportsStreamSubscription>('/sports-stream/sportradar/subscription', params);

    return response.data;
  }

  @refreshAuthToken()
  async getTeams(params: GetStreamTeamParams) {
    const response = await this.axios.get<GetStreamTeamsResponse>('/sports-stream/sportradar/wnba/teams', {
      params
    });

    return response.data;
  }
}

export const sportsStreamApi = new SportsStreamApi(instance);
