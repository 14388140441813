import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap';
import { TENANT_LICENSES } from '@pushologies/common/src/constants/tenant-licenses';
import { tenantApi } from '../../api/tenant-api';

interface State {
  email: string;
  name: string;
  eventsExpireIn: number;
  personalisationLicense: boolean;
  pollingLicense: boolean;
  platformHostingLicense: boolean;
  loading: boolean;
}

const defaultState: State = {
  email: '',
  name: '',
  eventsExpireIn: 32,
  personalisationLicense: true,
  pollingLicense: true,
  platformHostingLicense: false,
  loading: false
};

export default class CreateTenant extends Component<null, State> {
  constructor(props: any) {
    super(props);
    this.state = { ...defaultState };
  }

  onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  };

  onChangeEventsExpire = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ eventsExpireIn: Number(e.target.value) });
  };

  onChangeTenantName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: e.target.value });
  };

  onChangePersonalisation = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ personalisationLicense: e.target.checked });
  };

  onChangePolling = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ pollingLicense: e.target.checked });
  };

  onChangePlatformHosting = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ platformHostingLicense: e.target.checked });
  };

  onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    this.setState({ loading: true });
    const licenses = [];
    this.state.personalisationLicense && licenses.push(TENANT_LICENSES.PERSONALISED_NOTIFICATIONS);
    this.state.pollingLicense && licenses.push(TENANT_LICENSES.POLLING_NOTIFICATIONS);
    this.state.platformHostingLicense && licenses.push(TENANT_LICENSES.PLATFORM_CONTENT_HOSTING);

    try {
      await tenantApi.create({
        email: this.state.email,
        name: this.state.name,
        eventsExpireIn: this.state.eventsExpireIn,
        ...(licenses.length && { licenses })
      });

      toast('Tenant created successfully', {
        position: 'bottom-right',
        autoClose: 5000,
        type: 'success',
        hideProgressBar: true,
        closeOnClick: false,
        closeButton: false,
        pauseOnHover: false,
        draggable: false
      });
      this.setState({ ...defaultState });
    } catch (error) {
      toast(error.message, {
        position: 'bottom-right',
        autoClose: 5000,
        type: 'error',
        hideProgressBar: true,
        closeOnClick: false,
        closeButton: false,
        pauseOnHover: false,
        draggable: false
      });
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, email, name, eventsExpireIn } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <FormGroup>
          <FormLabel>Main User Email</FormLabel>
          <FormControl type="email" required onChange={this.onChangeEmail} value={email} />
        </FormGroup>
        <FormGroup>
          <FormLabel>Tenant Name</FormLabel>
          <FormControl type="text" required onChange={this.onChangeTenantName} value={name} />
        </FormGroup>
        <FormGroup>
          <FormLabel>Events Expiry Time (minimum must be 32 days)</FormLabel>
          <FormControl type="number" defaultValue={32} onChange={this.onChangeEventsExpire} value={eventsExpireIn} />
        </FormGroup>
        <FormGroup>
          <FormLabel>Licenses</FormLabel>
          <Form.Check
            type="switch"
            id="personalisation-switch"
            label="Personalisation"
            defaultChecked
            onChange={this.onChangePersonalisation}
          />
          <Form.Check
            type="switch"
            id="polling-switch"
            label="Polling"
            defaultChecked
            onChange={this.onChangePolling}
          />
          <Form.Check
            type="switch"
            id="hosting-switch"
            label="Platform Content Hosting"
            onChange={this.onChangePlatformHosting}
          />
        </FormGroup>
        <Button type="submit" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
        </Button>
      </Form>
    );
  }
}
