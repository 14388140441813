import React, { ChangeEvent, KeyboardEvent, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Routes } from './routes';
import { Button, FormControl } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

const toastConfig: any = {
  position: 'bottom-right',
  autoClose: 5000,
  type: 'error',
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  pauseOnHover: false,
  draggable: false
};

export const App = () => {
  const [authState, setAuthState] = React.useState<string>();
  const [authData, setAuthData] = React.useState({
    email: '',
    password: '',
    newPassword: ''
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => setAuthState('signedIn'))
      .catch(() => false);
  }, []);

  const handleFormChange = (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setAuthData({ ...authData, [key]: event.target.value });
  };

  const handleLogin = async () => {
    try {
      const cognitoUser = await Auth.signIn({
        username: authData.email,
        password: authData.password
      });
      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return setAuthState('choosePassword');
      }
      setAuthState('signedIn');
    } catch (e) {
      toast('Please check your details and try again', toastConfig);
    }
  };

  const handleRegister = async () => {
    try {
      const unconfirmedUser = await Auth.signIn({
        username: authData.email,
        password: authData.password
      });
      await Auth.completeNewPassword(unconfirmedUser, authData.newPassword, null);
      setAuthState('signedIn');
    } catch (e) {
      toast('Please check your details and try again', toastConfig);
    }
  };

  const handleSignOut = () => {
    Auth.signOut({ global: true });
    setAuthState(null);
  };

  if (authState === 'signedIn') {
    return <Routes onSignOut={handleSignOut} />;
  }

  return (
    <>
      <div className="card">
        {authState === 'choosePassword' ? (
          <div>
            <h5>Choose a new password</h5>
            <FormControl type={'password'} placeholder="New password" onChange={handleFormChange('newPassword')} />
            <Button onClick={handleRegister}>Register</Button>
          </div>
        ) : (
          <>
            <h5>Sign in to your account</h5>
            <span>Username:</span>
            <FormControl type={'email'} placeholder={'Enter your username'} onChange={handleFormChange('email')} />
            <span>Password:</span>
            <FormControl
              type={'password'}
              placeholder={'Enter your password'}
              onChange={handleFormChange('password')}
              onKeyUp={(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                  handleLogin();
                }
              }}
            />
            <Button onClick={handleLogin}>Sign in</Button>
          </>
        )}
      </div>
      <ToastContainer />
    </>
  );
};
