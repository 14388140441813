import axios, { AxiosInstance } from 'axios';
import { Auth } from '@aws-amplify/auth';

export const instance: AxiosInstance = axios.create({
  baseURL: process.env.API_URL
});

export function addIdTokenToHeaders() {
  const storage = (Auth as any)?._storage ?? {};
  const idTokenKey = Object.keys(storage).find((key) => key.match(/idToken$/));

  instance.defaults.headers.common = {
    'Content-Type': 'application/json',
    ...(idTokenKey && { Authorization: `Bearer ${storage[idTokenKey]}` })
  };
}

addIdTokenToHeaders();
