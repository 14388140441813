const theme = {
  metrics: {
    fontSize: '1rem',
    headerHeight: '3.8rem',
    headerOverlayHeight: '2.7rem',
    sideNavWidth: '17vw'
  },
  palette: {
    apple: '#96969A',
    android: '#3DDC84',
    lightBlue: '#e7f3ff',
    primaryPlum: '#A71680',
    primaryMustard: '#F9AF05',
    secondaryPurple: '#5C1F84',
    platinum: '#9F9F9F',
    textColor: '#5C5C5F',
    navGreyColor: '#838383',
    darkGrey: '#d7d7d7',
    lightGrey: '#d9d9d9',
    lighterGrey: '#EBEBEB',
    faintGrey: '#f8f8f8',
    textGrey: '#999999',
    textLightGrey: '#c2c2c2',
    darkenedWhite: '#F7F7F7',
    bodyBgColor: 'white',
    topBarBgColor: 'white',
    formBgColor: '#f6f6f6',
    inputBorderColor: '#acacac',
    successPrimary: '#a6d465',
    successSecondary: '#f9fff3',
    infoPrimary: '#007bff',
    infoSecondary: '#f3f9ff',
    errorPrimary: '#7d1d26',
    errorSecondary: '#f5f0f0',
    purple: '#7F62C8',
    blue: '#4378AA',
    faintBlue: '#bfd5ee',
    red: '#DE283C',
    black: '#000'
  }
};

type Theme = typeof theme;

declare module 'styled-components' {
  // tslint:disable-next-line: no-empty-interface
  export interface DefaultTheme extends Theme {}
}

export default theme;
