// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./MrEavesXLModOT-Reg.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./MrEavesXLModOT-Bold.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "./MrEavesXLModOT-Heavy.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:EavesRegular;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");font-weight:normal;font-style:normal}@font-face{font-family:EavesBold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");font-weight:normal;font-style:normal}@font-face{font-family:EavesHeavy;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:normal;font-style:normal}", "",{"version":3,"sources":["webpack://./assets/fonts/fonts.css"],"names":[],"mappings":"AAAA,WACE,wBAAA,CACA,8DAAA,CACA,kBAAA,CACA,iBAAA,CAGF,WACE,qBAAA,CACA,8DAAA,CACA,kBAAA,CACA,iBAAA,CAGF,WACE,sBAAA,CACA,8DAAA,CACA,kBAAA,CACA,iBAAA","sourcesContent":["@font-face {\n  font-family: EavesRegular;\n  src: url('./MrEavesXLModOT-Reg.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: EavesBold;\n  src: url('./MrEavesXLModOT-Bold.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: EavesHeavy;\n  src: url('./MrEavesXLModOT-Heavy.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
