import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, FormControl, FormGroup, FormLabel, Spinner, Table } from 'react-bootstrap';
import { TENANT_LICENSES } from '@pushologies/common/src/constants/tenant-licenses';
import { Tenant, tenantApi } from '../../api/tenant-api';

const UpdateTenant = () => {
  const [loading, setLoading] = useState(true);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [tenant, setTenant] = useState<Partial<Tenant>>();
  const [parentId, setParentId] = useState<string>();
  const [licenceRenderKey, setLicenceRenderKey] = useState(new Date().getTime());
  const [cloudfrontStatusMap, setCloudfrontStatusMap] = useState<Record<string, { status: string; enabled?: boolean }>>(
    {}
  );

  const onChangeField = (field: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setTenant({ ...tenant, [field]: event.target.value });
  };

  const onChangeLicense = (type: TENANT_LICENSES) => {
    setTenant({
      ...tenant,
      licenses: tenant.licenses?.includes(type)
        ? tenant.licenses?.filter((eachLicence) => eachLicence !== type)
        : [...tenant.licenses, type]
    });
  };

  const getTenants = async () => {
    const { tenants } = await tenantApi.get();
    setTenants(tenants);
    setLoading(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      // do we need update the link between this tenant and another?
      const originalData = tenants.find(({ id }) => id === tenant.id);
      if (originalData.parentId !== parentId) {
        // handle unlinking
        if (!parentId && !!originalData.parentId) {
          await tenantApi.unlink(originalData.parentId, tenant.id);
        }

        // handle linking
        if (!!parentId) {
          await tenantApi.link(parentId, tenant.id);
        }
      }

      // update tenant data
      await tenantApi.update(tenant);

      toast('Tenant updated successfully', {
        position: 'bottom-right',
        autoClose: 5000,
        type: 'success',
        hideProgressBar: true,
        closeOnClick: false,
        closeButton: false,
        pauseOnHover: false,
        draggable: false
      });
      setTenant(undefined);
      getTenants();
    } catch (e) {
      toast('Tenant update failed', {
        position: 'bottom-right',
        autoClose: 5000,
        type: 'error',
        hideProgressBar: true,
        closeOnClick: false,
        closeButton: false,
        pauseOnHover: false,
        draggable: false
      });
    }
    setLoading(false);
  };

  const getTenantCloudfrontStatus = async (tenantId: string) => {
    setCloudfrontStatusMap({ ...cloudfrontStatusMap, [tenantId]: { status: 'Loading' } });
    const result = await tenantApi.getCloudfrontDistributionStatus(tenantId);
    setCloudfrontStatusMap({ ...cloudfrontStatusMap, [tenantId]: result });
    return result;
  };

  useEffect(() => {
    getTenants();
  }, []);

  return !tenant ? (
    <div>
      <h2>Choose a tenant to update</h2>
      <p>You can update tenant database information by clicking the "Edit" action on the desired tenant row below.</p>
      <p>
        You can also use this table to make updates to tenant Cloudfront Distributions. These updates take many minutes
        to apply, so please use the refresh button against each tenant row to fetch their current status/available
        actions as needed.
      </p>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Tenant Name</th>
            <th>Parent</th>
            <th>Licences</th>
            <th>Has own Cloudfront Distribution?</th>
            <th>Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tenants.map(
            ({ id, name, email, eventsExpireIn, licenses, parentId, createdAt, cloudfrontDistributionId }) => (
              <tr key={id}>
                <td>{name}</td>
                <td>{parentId && tenants.find((eachTenant) => eachTenant.id === parentId)?.name}</td>
                <td>
                  <ul>
                    {licenses?.map((eachLicence) => (
                      <li key={eachLicence}>{eachLicence}</li>
                    ))}
                  </ul>
                </td>
                <td>
                  <div className="cloudfront-table-cell">
                    {cloudfrontStatusMap[id]?.status === 'Loading' ? (
                      <>Updating...</>
                    ) : (
                      <>
                        {cloudfrontDistributionId || cloudfrontStatusMap[id] ? (
                          <>
                            <Button
                              disabled={loading}
                              color="secondary"
                              variant="secondary"
                              size={'sm'}
                              onClick={() => getTenantCloudfrontStatus(id)}
                              className={'cloudfront-refresh-button'}
                            >
                              {loading ? (
                                <Spinner animation="border" />
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  style={{ width: 20, height: 20 }}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                  />
                                </svg>
                              )}
                            </Button>
                            Yes ({cloudfrontDistributionId})
                            <br />
                            Status: {!cloudfrontStatusMap[id] && <>...</>}
                            {cloudfrontStatusMap[id]?.status === 'InProgress' && cloudfrontStatusMap[id]?.enabled && (
                              <>Enabling</>
                            )}
                            {cloudfrontStatusMap[id]?.status === 'Deployed' && cloudfrontStatusMap[id]?.enabled && (
                              <>
                                Active
                                <div>
                                  <Button
                                    disabled={loading}
                                    color="secondary"
                                    variant="secondary"
                                    size={'sm'}
                                    onClick={async () => {
                                      setCloudfrontStatusMap({ ...cloudfrontStatusMap, [id]: { status: 'Loading' } });
                                      await tenantApi.disableCloudfrontDistribution(id);
                                      getTenantCloudfrontStatus(id);
                                    }}
                                  >
                                    {loading ? <Spinner animation="border" /> : 'Disable'}
                                  </Button>
                                </div>
                              </>
                            )}
                            {cloudfrontStatusMap[id]?.status === 'InProgress' && !cloudfrontStatusMap[id]?.enabled && (
                              <>Disabling</>
                            )}
                            {cloudfrontStatusMap[id]?.status === 'Deployed' && !cloudfrontStatusMap[id]?.enabled && (
                              <>
                                Disabled
                                <div>
                                  <Button
                                    disabled={loading}
                                    color="secondary"
                                    variant="secondary"
                                    size={'sm'}
                                    onClick={async () => {
                                      setCloudfrontStatusMap({ ...cloudfrontStatusMap, [id]: { status: 'Loading' } });
                                      await tenantApi.enableCloudfrontDistribution(id);
                                      getTenantCloudfrontStatus(id);
                                    }}
                                  >
                                    {loading ? <Spinner animation="border" /> : 'Re-enable'}
                                  </Button>{' '}
                                  <Button
                                    disabled={loading}
                                    color="secondary"
                                    variant="secondary"
                                    size={'sm'}
                                    onClick={async () => {
                                      setCloudfrontStatusMap({ ...cloudfrontStatusMap, [id]: { status: 'Loading' } });
                                      await tenantApi.deleteCloudfrontDistribution(id);
                                      setCloudfrontStatusMap({ ...cloudfrontStatusMap, [id]: undefined });
                                      setTenants([
                                        ...tenants.map((eachTenant) =>
                                          eachTenant.id === id
                                            ? {
                                                ...eachTenant,
                                                cloudfrontDistributionId: undefined
                                              }
                                            : eachTenant
                                        )
                                      ]);
                                    }}
                                  >
                                    {loading ? <Spinner animation="border" /> : 'Delete'}
                                  </Button>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            No
                            <div>
                              <Button
                                disabled={loading}
                                color="secondary"
                                variant="secondary"
                                size={'sm'}
                                onClick={async () => {
                                  setCloudfrontStatusMap({ ...cloudfrontStatusMap, [id]: { status: 'Loading' } });
                                  await tenantApi.createCloudfrontDistribution(id);
                                  const { id: newDistributionId } = await getTenantCloudfrontStatus(id);
                                  setTenants([
                                    ...tenants.map((eachTenant) =>
                                      eachTenant.id === id
                                        ? {
                                            ...eachTenant,
                                            cloudfrontDistributionId: newDistributionId
                                          }
                                        : eachTenant
                                    )
                                  ]);
                                }}
                              >
                                {loading ? <Spinner animation="border" /> : 'Create'}
                              </Button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </td>
                <td>{new Date(createdAt).toISOString().split('T')[0]}</td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setParentId(parentId);
                      setTenant({
                        id,
                        name,
                        email,
                        eventsExpireIn,
                        licenses
                      });
                      setLicenceRenderKey(new Date().getTime());
                    }}
                  >
                    Edit
                  </a>{' '}
                </td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    </div>
  ) : (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Button
          disabled={loading}
          color="secondary"
          variant="secondary"
          size={'sm'}
          onClick={() => {
            setTenant(undefined);
            getTenants();
          }}
        >
          {loading ? <Spinner animation="border" /> : '<< Discard changes'}
        </Button>
      </FormGroup>
      <FormGroup>
        <FormLabel>Main User Email</FormLabel>
        <FormControl type="email" required onChange={onChangeField('email')} value={tenant.email} />
      </FormGroup>
      <FormGroup>
        <FormLabel>Tenant Name</FormLabel>
        <FormControl type="text" required onChange={onChangeField('name')} value={tenant.name} />
      </FormGroup>
      <FormGroup>
        <FormLabel>Events Expiry Time (minimum must be 32 days)</FormLabel>
        <FormControl
          type="number"
          defaultValue={32}
          onChange={onChangeField('eventsExpireIn')}
          value={tenant.eventsExpireIn}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Licenses</FormLabel>
        {Object.values(TENANT_LICENSES).map((eachLicence) => (
          <Form.Check
            key={eachLicence + licenceRenderKey}
            id={eachLicence}
            type="switch"
            label={eachLicence}
            defaultChecked={tenant.licenses?.includes(eachLicence)}
            onChange={() => onChangeLicense(eachLicence)}
          />
        ))}
      </FormGroup>

      <FormGroup>
        <FormLabel>Parent Tenant</FormLabel>
        <Form.Row>
          <select onChange={(event: ChangeEvent<HTMLSelectElement>) => setParentId(event.target.value)}>
            <option value={''}>This is not a Child Tenant</option>
            {tenants
              .filter(({ id }) => id !== tenant.id)
              .map(({ id, name }) => (
                <option key={id} value={id} selected={parentId === id}>
                  {name}
                </option>
              ))}
          </select>
        </Form.Row>
      </FormGroup>
      <Button type="submit" disabled={loading}>
        {loading ? <Spinner animation="border" size="sm" /> : 'Update'}
      </Button>
    </Form>
  );
};

export default UpdateTenant;
