import styled, { css } from 'styled-components';
import { Button, ButtonProps, Modal } from 'react-bootstrap';

export const HideScrollbars = css`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
`;

const placeholderStyles = css`
  font-family: EavesRegular;
  font-size: 0.9rem;
  letter-spacing: 0;
  color: ${({ theme }) => theme.palette.lightGrey};
  text-transform: uppercase;
`;

interface InputSectionProps {
  invalid?: boolean;
  $disabled?: boolean;
}

export const InputSection = styled.section<InputSectionProps>`
  position: relative;
  border: 1px solid
    ${({ theme, invalid, $disabled }) => {
      if ($disabled) return theme.palette.lightGrey;
      if (invalid) return 'red !important';
      return theme.palette.platinum;
    }};
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
  background: white;
  height: 100%;
  min-height: 1.9rem;

  label {
    font-family: EavesRegular;
    font-size: 0.9rem;
    letter-spacing: 0;
    color: ${({ theme, $disabled }) => ($disabled ? theme.palette.lightGrey : theme.palette.platinum)};
    text-transform: uppercase;
    margin: 0 0.5rem 0 0;
    line-height: 0.9rem;
    white-space: nowrap;

    span {
      color: ${({ theme }) => theme.palette.darkGrey};
      font-size: 0.85rem;
    }
  }

  input,
  textarea {
    border: none;
    width: 100%;
    color: ${({ theme, $disabled }) => ($disabled ? theme.palette.lightGrey : theme.palette.primaryPlum)};
    font-size: 1.1rem;
    font-family: EavesRegular;

    ::-webkit-input-placeholder {
      /* Edge */
      ${placeholderStyles}
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      ${placeholderStyles}
    }

    ::placeholder {
      ${placeholderStyles}
    }
  }

  input {
    height: 100%;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: no-drop;
      pointer-events: none !important;
    `}
`;

export const StyledJsonBuilderModal = styled(Modal)`
  overflow-y: hidden !important;

  .modal-dialog {
    max-width: unset;
    min-height: unset;
    height: 80vh;
    width: 60vw;
    min-width: 980px;

    .modal-content {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;

      .modal-body {
        overflow-y: scroll;
        padding-right: 0;
        padding-left: 0;

        ${HideScrollbars}
        .tab-content {
          padding: 10px;

          .row {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .monaco-editor .editor-widget {
    display: none !important;
    visibility: hidden !important;
  }
`;

export const StyledJsonBuilderModalBody = styled(Modal.Body)`
  padding: 20px 40px;
`;
export const StyledJsonBuilderInvalidStatus = styled.div`
  color: red;
`;

interface StyledButtonProps extends ButtonProps {
  $loading: boolean;
  $loadingPercentage: number;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  position: relative;
  border-radius: 2rem;
  padding: 0.375rem 1.5rem;

  div.spinner-border {
    position: absolute;
    top: calc(50% - 0.5rem);
    left: calc(50% - 0.5rem);
    z-index: 10;
  }

  span {
    opacity: ${({ $loading }) => ($loading ? 0 : 1)};
    z-index: 10;
  }

  &::after {
    content: '';
    background: ${({ theme }) => theme.palette.primaryPlum};
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.2s linear;
    width: ${({ $loadingPercentage }) => $loadingPercentage || '0'}%;
    height: 100%;
    border-radius: 2rem;
  }
`;

export const StyledSpan = styled.span`
  width: 1rem;
`;
