import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { StyledJsonBuilderInvalidStatus, StyledJsonBuilderModal, StyledJsonBuilderModalBody } from '../shared/styles';
import Editor from '@monaco-editor/react';

interface Props {
  isOpen: boolean;
  value: any;
  onChange: (newValue: any) => void;
  onClose: () => void;
  eventType: string;
}

export const TemplateBuilder: FC<Props> = ({ isOpen, value, onChange, onClose, eventType }) => {
  const [localValue, setLocalValue] = useState<any>();
  const [showError, setShowError] = useState(false);
  const editorRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setLocalValue(value ? JSON.parse(value) : null);
    }
  }, [value, isOpen]);

  const handleEditorDidMount = (editor: any) => {
    editorRef.current = editor;
  };

  const handleEditorChange = (updatedValue: string) => {
    try {
      setLocalValue(JSON.parse(updatedValue));
      setShowError(false);
    } catch (e) {
      setShowError(true);
    }
  };

  const isJsonValid = useMemo(() => {
    if (showError) return false;
    if (!localValue) return true;
    try {
      const valueString = JSON.stringify(localValue);
      return valueString.length <= 300;
    } catch (e) {
      return false;
    }
  }, [localValue, showError]);

  const handleClose = () => {
    setLocalValue(null);
    setShowError(false);
    onClose();
  };

  const handleSave = () => {
    const newValue = JSON.stringify(localValue);
    onChange(newValue === '{}' ? null : newValue);
    onClose();
  };

  return (
    <StyledJsonBuilderModal show={isOpen} backdrop="static" centered>
      <StyledJsonBuilderModalBody>
        <h5>{eventType} Template</h5>

        <Editor
          height="45vh"
          defaultLanguage="json"
          onMount={handleEditorDidMount}
          onChange={handleEditorChange}
          value={JSON.stringify(localValue, null, 2)}
        />
      </StyledJsonBuilderModalBody>
      <Modal.Footer>
        {!isJsonValid && (
          <StyledJsonBuilderInvalidStatus>
            Your template must be a valid JSON string that is less than 300 characters in length.
          </StyledJsonBuilderInvalidStatus>
        )}
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={!isJsonValid}>
          Save
        </Button>
      </Modal.Footer>
    </StyledJsonBuilderModal>
  );
};
